/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";

import { GlobalState } from "./src/components/GlobalState/GlobalState.js";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
  return <GlobalState>{element}</GlobalState>;
};
